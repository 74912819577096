import React from "react";
import { useContext } from "react";
import BannerCol from "../elements/BannerCol";
import { LanguageContext } from "../../store/language_context";

// Import Image
const ico = "/media/userCounts-img/family.png";
const ico2 = "/media/userCounts-img/comp.png";
const ico3 = "/media/userCounts-img/home.png";

const BannerSec = ({ userCounts }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const family = userCounts?.find((item) => item.option === "families_count");
  const secFamily = userCounts?.find((item) => item.option === "beds_count");
  const medical_committees_count = userCounts?.find(
    (item) => item.option === "medical_committees_count"
  );

  return (
    <div className="BannerSec text-center">
      <div className="container">
        <h5 className="title pb-3 text-light ">
          البيت هي مصدر ثقة آلاف العائلات والأفراد والشركات في السعودية.. كن
          جزءاً منهم!
        </h5>

        <div className="insider">
          <BannerCol
            icon={ico}
            num={Number(family?.value)}
            title={language === "en" ? "Family" : "عائلة"}
          />
          <BannerCol
            icon={ico2}
            num={Number(secFamily?.value)}
            title={language === "en" ? "Company" : "شركة"}
          />
          <BannerCol
            icon={ico3}
            num={Number(medical_committees_count?.value)}
            title={language === "en" ? "Home Visit" : "زيارة منزلية"}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerSec;
