import React from "react";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { IoShareSocialOutline } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";
import { CiLink } from "react-icons/ci";
import { useContext } from "react";
import axios from "axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
  LinkedinIcon,
  TelegramIcon,
} from "react-share";
import { LanguageContext } from "../../store/language_context";

const Service = ({
  photo,
  icon,
  title,
  excerpt,
  price,
  link,
  onClick,
  serviceId,
  priceBefore,
  discount,
}) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  const increaseClicks = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/addClickToService/${serviceId}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <>
      {price && photo && (
        <div className="col-md-3 offer-card" onClick={onClick}>
          <div className="serv">
            <i className={icon}></i>
            <div className="mges">
              <img src={photo} alt="#" />
              {discount && <div className="discount">%{discount}</div>}
            </div>

            <div className="title">
              <h4>{title}</h4>
              <ShowMoreText
                lines={1}
                more="المزيد"
                less="اقل"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                expandByClick={true}
                // width={}
                truncatedEndingComponent={"... "}
              >
                <p>{excerpt}</p>
              </ShowMoreText>
            </div>

            <div className="share">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{ border: "none", background: "transparent" }}
                >
                  <IoShareSocialOutline
                    color="#0282B9"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <FacebookShareButton
                      url={link}
                      className="Demo__some-network__share-button"
                    >
                      Facebook{" "}
                      <FacebookIcon
                        size={32}
                        round
                        style={{ marginRight: "10px" }}
                      />
                    </FacebookShareButton>{" "}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <TwitterShareButton
                      url={link}
                      className="Demo__some-network__share-button"
                    >
                      X{" "}
                      <XIcon size={32} round style={{ marginRight: "10px" }} />
                    </TwitterShareButton>{" "}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <WhatsappShareButton
                      url={link}
                      className="Demo__some-network__share-button"
                    >
                      Whatsapp{" "}
                      <WhatsappIcon
                        size={32}
                        round
                        style={{ marginRight: "10px" }}
                      />
                    </WhatsappShareButton>{" "}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <LinkedinShareButton
                      url={link}
                      className="Demo__some-network__share-button"
                    >
                      Linkedin{" "}
                      <LinkedinIcon
                        size={32}
                        round
                        style={{ marginRight: "10px" }}
                      />
                    </LinkedinShareButton>{" "}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <TelegramShareButton
                      url={link}
                      className="Demo__some-network__share-button"
                    >
                      Telegram{" "}
                      <TelegramIcon
                        size={32}
                        round
                        style={{ marginRight: "10px" }}
                      />
                    </TelegramShareButton>{" "}
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item>
                    <div onClick={copyLink}>
                      Copy Link{" "}
                      <CiLink size={32} style={{ marginRight: "10px" }} />
                    </div>{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="price">
              <span>{price} SR</span>
              {priceBefore && <span>{priceBefore} SR</span>}
              <Link
                to={link}
                className="btn btn-primary reserve-btn"
                onClick={increaseClicks}
              >
                {language === "en" ? "Book" : "احجز"}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Service;
