import React from "react";
import HeadSection from "../elements/HeadSection";
import MyTap from "../elements/MyTap";

const ServiceSec = ({ servicesList }) => {
  return (
    <div className="Services" id="ourServices">
      <div className="container">
        <HeadSection h3AR={"خدماتنا"} h3EN={"Our Services"} />

        <div className="Tabsy">
          <MyTap servicesList={servicesList} />
        </div>
      </div>
    </div>
  );
};

export default ServiceSec;
