import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LanguageContext } from "../../../store/language_context";
import Service from "../Service";

class TapOne extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  setCurrentSlide = (newIndex) => {
    this.setState({ currentSlide: newIndex });
  };

  render() {
    const { offers } = this.props;
    const language = this.context.language;
    const { currentSlide } = this.state;

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      beforeChange: (oldIndex, newIndex) => this.setCurrentSlide(newIndex),
      customPaging: function (i) {
        const tabStyle = {
          width: "30px",
          height: "5px",
          background: i === currentSlide ? "#0282B9" : "#8BC6DF",
          borderRadius: "5px",
          margin: "5px",
        };

        return (
          <div
            key={i}
            className={`dot ${i === currentSlide ? "active" : ""}`}
            style={{ ...tabStyle }}
          />
        );
      },
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 558,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <>
        <div className="mobile-top-offer">
          <div className="card-slider-container">
            <Slider {...settings}>
              {offers?.map((offer, index) => (
                <Service
                  key={index}
                  itemId={index}
                  photo={`https://albaitapi.seet-tec.com/${offer?.image}`}
                  icon={`https://albaitapi.seet-tec.com/${offer?.image}`}
                  title={offer?.title}
                  excerpt={offer?.excerpt}
                  price={offer?.offer?.priceAfter}
                  priceBefore={offer?.offer?.priceBefore}
                  discount={offer?.offer?.discount}
                  link={offer?.exLink}
                  content={offer?.content}
                  btnName={language === "en" ? "reserve" : "احجز"}
                />
              ))}
            </Slider>
          </div>
        </div>
        <div className="row offer-container-large">
          {offers?.map((offer, index) => (
            <Service
              key={index}
              photo={`https://albaitapi.seet-tec.com/${offer?.image}`}
              icon={`https://albaitapi.seet-tec.com/${offer?.image}`}
              title={offer?.title}
              excerpt={offer?.excerpt}
              price={offer?.offer?.priceAfter}
              priceBefore={offer?.offer?.priceBefore}
              discount={offer?.offer?.discount}
              link={offer?.exLink}
              content={offer?.content}
              btnName={language === "en" ? "reserve" : "احجز"}
            />
          ))}
        </div>
      </>
    );
  }
}

export default TapOne;
