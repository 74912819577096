import React from "react";
import Feature from "../elements/Features";

const FeaturesSec = ({ features }) => {
  features = [features?.box1, features?.box2, features?.box3, features?.box4];

  return (
    <div className="Features text-center">
      <div className="container">
        <div className="insider">
          {features?.map((feature, index) => (
            <Feature
              key={index}
              icon={`https://albaitapi.seet-tec.com${feature?.image}`}
              title={feature?.title}
              content={feature?.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSec;
