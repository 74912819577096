import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Service from "../Service";

class TapTow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  setCurrentSlide = (newIndex) => {
    this.setState({ currentSlide: newIndex });
  };

  render() {
    const { articles, serviceId } = this.props;
    const { currentSlide } = this.state;

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      beforeChange: (oldIndex, newIndex) => this.setCurrentSlide(newIndex),
      customPaging: function (i) {
        const tabStyle = {
          width: "30px",
          height: "5px",
          background: i === currentSlide ? "#0282B9" : "#8BC6DF",
          borderRadius: "5px",
          margin: "5px",
        };

        return (
          <div
            key={i}
            className={`dot ${i === currentSlide ? "active" : ""}`}
            style={{ ...tabStyle }}
          />
        );
      },
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 558,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <>
        <div className="mobile-top-offer">
          <div className="card-slider-container">
            <Slider {...settings}>
              {articles?.map((article, index) => (
                <Service
                  key={index}
                  icon={article.image}
                  title={article.title}
                  excerpt={article.excerpt}
                  link={article.exLink}
                  photo={`https://albaitapi.seet-tec.com${article.image}`}
                  price={article.price}
                  serviceId={serviceId}
                />
              ))}
            </Slider>
          </div>
        </div>
        <div className="row offer-container-large">
          {articles?.map((article, index) => (
            <Service
              key={index}
              icon={article.image}
              title={article.title}
              excerpt={article.excerpt}
              link={article.exLink}
              photo={`https://albaitapi.seet-tec.com${article.image}`}
              price={article.price}
              serviceId={serviceId}
            />
          ))}
        </div>
      </>
    );
  }
}

export default TapTow;
