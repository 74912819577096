import React from "react";
import { HashRouter, Route, Routes, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "./store/language_context";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import SingleBlog from "./pages/SingleBlog";
import Contact from "./pages/Contact";
import ServiceDetail from "./components/elements/ServiceDetail ";
import ArticleDetail from "./components/elements/articleDetail";
import ServiceData from "./pages/ServicesData";
import TermsAndPrivacy from "./pages/TermsAndPrivacy";
import NotFound from "./pages/NotFound";
import "./App.css";

function App() {
  const [offerData, setOfferData] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}`, {
          headers: {
            locale: language,
          },
        });
        setOfferData(data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    const getArticles = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/articles`,
          {
            headers: {
              locale: language,
            },
          }
        );
        setArticles(data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
    getArticles();
  }, [language]);

  const title = offerData?.SiteIdentities?.find(
    (val) => val?.option === "site_name"
  );
  const description = offerData?.SiteIdentities?.find(
    (val) => val?.option === "slogan"
  );
  const icon = offerData?.SiteIdentities?.find(
    (val) => val?.option === "fav_icon"
  );

  const headerScripts = offerData?.SiteIdentities?.find(
    (val) => val?.option === "header_scripts"
  );

  const footerScripts = offerData?.SiteIdentities?.find(
    (val) => val?.option === "footer_scripts"
  );

  return (
    <>
      <Helmet>
        <title>{title?.value}</title>
        <meta name="og:title" content={title?.value} />
        <meta name="description" content={description?.value} />
        <meta name="og:description" content={description?.value} />
        <meta name="twitter:title" content={title?.value} />
        <meta name="twitter:description" content={description?.value} />
        <link
          rel="icon"
          type="image/x-icon"
          href={`https://albaitapi.seet-tec.com${icon?.value}`}
        />
        //Web
        <link
          rel="icon"
          type="image/png"
          href="/media/Logo.png"
          sizes="96x96"
        />
        //Apple
        <link
          rel="apple-touch-icon"
          type="image/png"
          href="/media/Logo.png"
          sizes="96x96"
        />
        //Android
        <link
          rel="andorid-touch-icon"
          type="image/png"
          href="/media/Logo.png"
          sizes="96x96"
        />
        {headerScripts && (
          <script type="text/javascript" src={headerScripts.value} />
        )}
      </Helmet>

      <HashRouter>
        {loading ? (
          <TailSpin
            visible={loading}
            color="#0282B9"
            height={100}
            width={100}
            ariaLabel="tail-spin-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          />
        ) : (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/single" element={<SingleBlog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services/:service_id" element={<ServiceData />} />
            <Route
              path="/service/:id"
              element={
                <ServiceDetail
                  offers={offerData?.OffersList}
                  offerData={offerData}
                />
              }
            />
            <Route
              path="/article/:id"
              element={<ArticleDetail articles={articles?.ArticlesTabs} />}
            />
            <Route
              path="/terms/:id"
              element={<TermsAndPrivacy links={offerData?.PageLinks} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}

        {footerScripts && (
          <script type="text/javascript" src={footerScripts.value} />
        )}
      </HashRouter>

      <style>
        {`
          html {
            direction: ${language === "ar" ? "rtl" : "ltr"};
          }
        `}
      </style>
    </>
  );
}

export default App;
