import React from "react";
// import { useState, useEffect } from "react";
// import axios from "axios";
import { Link } from "react-router-dom";

// Import Media
// const phot = "/media/blog.png";

const SingleBlog = ({ rightArticle }) => {
  const language = localStorage.getItem("language") || "en";

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="single-blog">
      <Link to={`/article/${rightArticle?.exLink}`} onClick={handleClick}>
        <div className="photo">
          <span className="category">{rightArticle?.category_name}</span>
          <img
            src={`https://albaitapi.seet-tec.com${rightArticle?.image}`}
            alt="blog"
          />
        </div>
      </Link>
      <div className="content">
        <h3>{rightArticle?.title}</h3>
        <p>{rightArticle?.excerpt}</p>
      </div>
      <div className="date">
        {/* <Link to={`/article/${rightArticle?.exLink}`} target="_blank">
          {language === "en" ? "More..." : "المزيد..."}
        </Link> */}
      </div>
    </div>
  );
};

export default SingleBlog;
