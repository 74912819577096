import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="notfound-contaier">
      <div className="photo">
        <img src="/media/404.png" style={{ minHeight: "380px" }} />
      </div>
      <div className="content">
        <p>Page Not Found</p>
        <button>
          <Link to="/">GO HOME</Link>
        </button>
      </div>
    </div>
  );
}
