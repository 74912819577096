import React from "react";
import { useContext } from "react";
import ShowMoreText from "react-show-more-text";
import { LanguageContext } from "../../store/language_context";
import { Link } from "react-router-dom";

const ServiceInfo = ({ serInfo }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const baseUrl = "https://albaitapi.seet-tec.com";

  return (
    <>
      {serInfo?.price && serInfo?.image && (
        <div className="col-md-3 offer-card">
          <div className="serv">
            <div className="mges">
              <img src={baseUrl + serInfo?.image} alt="#" />
            </div>
            <div className="title">
              <h4>{serInfo?.title}</h4>
              <ShowMoreText
                lines={1}
                more="المزيد"
                less="اقل"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                expandByClick={true}
                width={150}
                truncatedEndingComponent={"... "}
              >
                <p>{serInfo?.excerpt}</p>
              </ShowMoreText>
            </div>
            <div className="price">
              <span>{serInfo?.price} SR</span>
              <Link
                to={serInfo?.exLink}
                className="btn btn-primary reserve-btn"
              >
                {language === "en" ? "Book" : "احجز"}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ServiceInfo;
