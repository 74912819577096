import React, { Fragment } from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { LanguageContext } from "../store/language_context";
import Header from "../components/Header";
import TapOne from "../components/elements/serviceTabs/TapOne";
import Footer from "../components/Footer";

function SingleBlog() {
  const [loading, setLoading] = useState(true);
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const [offerData, setOfferData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}`, {
          headers: {
            locale: language,
          },
        });
        setOfferData(data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <TailSpin
          visible={loading}
          color="#0282B9"
          height={100}
          width={100}
          ariaLabel="tail-spin-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div className="Page">
          <Header
            contacts={offerData.Contacts}
            siteIdentities={offerData.SiteIdentities}
            navLinks={offerData.NavLinks}
          />
          <div className="Offers">
            <div className="container">
              <div className="Tabsy">
                <TapOne offers={offerData?.OffersList} />
              </div>
            </div>
          </div>
          <Footer
            contacts={offerData.Contacts}
            pageLinks={offerData.PageLinks}
          />
        </div>
      )}
    </>
  );
}

export default SingleBlog;
