import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useState } from "react";
import TabButton from "./TabButton";
import TapTow from "./serviceTabs/TapTow";

const MyTap = ({ servicesList }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  // functions
  return (
    <Tabs
      id="uncontrolled-tab-example"
      activeKey={selectedTab}
      onSelect={(tab) => setSelectedTab(tab)}
      className="responsive-tabs"
      style={{ position: "relative" }}
    >
      {servicesList?.map((service, index) => (
        <Tab
          key={service.id}
          eventKey={index}
          active={index === 0 ? true : false}
          className={`${index === 0 ? "active" : ""}`}
          title={
            <TabButton
              icon={`https://albaitapi.seet-tec.com${service.cat_image}`}
              title={service?.category_name}
            />
          }
        >
          <TapTow
            articles={servicesList?.[selectedTab]?.Articles}
            serviceId={service.id}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

export default MyTap;
