import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../../../store/language_context";

// Import Media
// const phot = "/media/blog.png";

const BlogItem = ({ article, index }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link to={`/article/${article?.exLink}`} onClick={handleClick}>
      <div className="boxe">
        <img
          src={`https://albaitapi.seet-tec.com${article?.image}`}
          alt="blog"
        />
        <div className="conte">
          <h6 className="mb-3">{article?.title}</h6>
          {/* <Link
          to={`/article/${article?.exLink}`}
          className="mainLink"
          target="_blank"
        >
          {language === "en" ? "More..." : "المزيد..."}
        </Link> */}
        </div>
      </div>
    </Link>
  );
};

export default BlogItem;
