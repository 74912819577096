import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import axios from "axios";
import { LanguageContext } from "../store/language_context";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function TermsAndPrivacy({ links }) {
  const [pageData, setPageData] = useState();
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const { id } = useParams();

  useEffect(() => {
    const getSinglePage = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/page/${id}`,
          {
            headers: {
              locale: language,
            },
          }
        );
        setPageData(data?.data);
      } catch (err) {
        console.log(err);
      }
    };
    getSinglePage();
  }, [id]);

  return (
    <div>
      <Header
        contacts={pageData?.Contacts}
        siteIdentities={pageData?.SiteIdentities}
        navLinks={pageData?.NavLinks}
      />
      <div className="container">
        <h2 style={{ padding: "20px 0 0" }}>{pageData?.SelectedPage?.title}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: pageData?.SelectedPage?.content,
          }}
          style={{ padding: "20px 0" }}
        />
      </div>
      <Footer contacts={pageData?.Contacts} />
    </div>
  );
}
