import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useContext } from "react";
import MyNavbar from "./elements/MyNavbar";
import MyNavTow from "./elements/MyNavTow";
import { LanguageContext } from "../store/language_context";

const Whatsapp = "/media/whats.png";
const Insta = "/media/w5.png";
const Snap = "/media/w4.png";
const TicT = "/media/w3.png";
const Twitt = "/media/w2.png";

const GPlay = "/media/play.png";
const Apply = "/media/apple.png";

const Header = ({ contacts, siteIdentities, navLinks }) => {
  const whatsApp = contacts?.find((contact) => contact.option === "whatsapp");
  const twitter = contacts?.find((contact) => contact.option === "x_url");
  const instagram = contacts?.find((contact) => contact.option === "instagram");
  const snapchat = contacts?.find((contact) => contact.option === "snapchat");
  const tiktok = contacts?.find((contact) => contact.option === "tiktok");
  const googleStore = contacts?.find(
    (contact) => contact.option === "google_play_link"
  );
  const appleStore = contacts?.find(
    (contact) => contact.option === "app_store_link"
  );

  const logo = siteIdentities?.find(
    (siteIdentity) => siteIdentity.option === "logo"
  );
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  const setArabicLanguage = () => {
    languageContext.changeLanguage("ar");
    window.location.reload();
  };

  const setEnglishLanguage = () => {
    languageContext.changeLanguage("en");
    window.location.reload();
  };

  return (
    <>
      <div className="icons-nav-contain">
        <div className="container icons-nav">
          <div className="lango">
            {/* <Dropdown>
              <Dropdown.Toggle variant="lang" id="dropdown-basic">
                {language === "en" ? "En" : "AR"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={setArabicLanguage}>عربي</Dropdown.Item>
                <Dropdown.Item onClick={setEnglishLanguage}>
                  English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          <div className="social-ico">
            <Link to={whatsApp?.value} className="whats" target="_blank">
              <img src={Whatsapp} alt="Whats" />
            </Link>
            <Link to={instagram?.value} className="face" target="_blank">
              <img src={Insta} alt="instagram" />
            </Link>
            <Link to={twitter?.value} className="linke" target="_blank">
              <img src={Twitt} alt="twitter" />
            </Link>
            <Link to={snapchat?.value} className="twitt" target="_blank">
              <img src={Snap} alt="snap" />
            </Link>
            <Link to={tiktok?.value} className="twitt" target="_blank">
              <img src={TicT} alt="tiktok" />
            </Link>
          </div>
        </div>
      </div>
      <div className="Header">
        <div className="container">
          <div className="head-one">
            <div className="logo">
              <Link to={"/"}>
                <img
                  src={`https://albaitapi.seet-tec.com${logo?.value}`}
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="menu">
              <MyNavbar navLinks={navLinks} />
            </div>
            <div className="app-download">
              <h5>
                {language === "en"
                  ? "Download the app now"
                  : "حمّل التطبيق الآن"}
              </h5>
              <div className="logos-down">
                <Link
                  to={googleStore?.value}
                  className="google"
                  target="_blank"
                >
                  <img src={GPlay} alt="Play" />
                </Link>
                <Link to={appleStore?.value} className="apple" target="_blank">
                  <img src={Apply} alt="apple" style={{ maxWidth: "95px" }} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="head-tow">
          <div className="container">
            <div className="all-menu">
              <div className="menu-tow">
                <MyNavTow navLinks={navLinks} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
