import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import { LanguageContext } from "../../store/language_context";
import Header from "../Header";
import Footer from "../Footer";
import LastNews from "../sections/LastNews";

const ArticleDetail = ({ articles }) => {
  const [article, setArticle] = useState();
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const { id } = useParams();

  useEffect(() => {
    const getSingleArticle = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/article/${id}`,
          {
            headers: {
              locale: language,
            },
          }
        );
        setArticle(data?.data);
      } catch (err) {
        console.log(err);
      }
    };
    getSingleArticle();
  }, [id]);

  return (
    <div>
      <Header
        contacts={article?.Contacts}
        siteIdentities={article?.SiteIdentities}
        navLinks={article?.NavLinks}
      />
      <div className="Blogsec">
        <div className="container">
          <div className="image-container">
            <img
              src={`https://albaitapi.seet-tec.com${article?.SelectedArticle?.image}`}
            />
          </div>
          <div className="text-box">
            <span>{article?.SelectedArticle?.category_name}</span>
            <h2>{article?.SelectedArticle?.title}</h2>
            <p>{article?.SelectedArticle?.excerpt}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: article?.SelectedArticle?.content,
            }}
          ></div>
        </div>
      </div>
      <LastNews articles={articles} />
      <Footer contacts={article?.Contacts} />
    </div>
  );
};

export default ArticleDetail;
