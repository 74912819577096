import React, { Fragment } from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LastNews from "../components/sections/LastNews";
import { LanguageContext } from "../store/language_context";
import HomeBlogSec from "../components/sections/HomeBlogSec";

function Blogs() {
  const [loading, setLoading] = useState(true);
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/articles`,
          {
            headers: {
              locale: language,
            },
          }
        );
        setHomeData(data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);
  // console.log(homeData);
  return (
    <>
      {loading ? (
        <TailSpin
          visible={loading}
          color="#0282B9"
          height={100}
          width={100}
          ariaLabel="tail-spin-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <Fragment>
          {/* Header */}
          <Header
            contacts={homeData.Contacts}
            siteIdentities={homeData.SiteIdentities}
            navLinks={homeData.NavLinks}
          />

          <div className="Blogs">
            {/* BlogSec */}
            <HomeBlogSec articles={homeData.LatestArticles} />

            {/* Last News */}
            <LastNews articles={homeData.ArticlesTabs} />
          </div>

          {/* Footer */}
          <Footer contacts={homeData.Contacts} pageLinks={homeData.PageLinks} />
        </Fragment>
      )}
    </>
  );
}

export default Blogs;
